import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function brandarchiveUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'archive_id', label: '档案编号', sortable: true },
    { key: 'supplier_name', label: '供应商名称', sortable: true },
    { key: 'brand_name', label: '品牌中文名称', sortable: true },
    { key: 'area_name', label: '区域', sortable: true },
    //{ key: 'return_memo', label: '返利说明', sortable: true },
    { key: 'memo', label: '备注', sortable: true },
    { key: 'status', label: '状态', sortable: true },
    { key: 'state', label: '启用', sortable: true },
    //{ key: 'attachments', label: '附件', sortable: true },
    { key: 'create_time', label: '添加时间', sortable: true },
    { key: 'creator', label: '添加人', sortable: true },
    { key: 'update_time', label: '更新时间', sortable: true },
    { key: 'updator', label: '更新人', sortable: true },
    { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('archive_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const condition = {...store.getters['brandarchive/getCondition']}
    store
      .dispatch('brandarchive/search', {
        search_key: searchKey.value,
        area_name: condition.area_name,
        brand_name: condition.brand_name,
        supplier_name:condition.supplier_name,
        time:condition.time,
        status:condition.status!=null ? condition.status.value:null,
        creator:condition.user_id,
        state:condition.state,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
